export enum Issuers {
  OEM = "OEM",
  TA = "TA",
  PSP = "PSP",
  PLO = "PLO",
}

// export enum Issuers {
//     Government = "GOVERNMENT",
//     CompanyHouse = "COMPANY_HOUSE",
//     Bank = "BANK",
//     Insurance = "INSURANCE",
// }
